import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import HomeRing from '../../Assets/images/cultured-img/home-ring.jpg'
import s1 from '../../Assets/images/cultured-img/s1.png'
import s2 from '../../Assets/images/cultured-img/S2.png'
import s3 from '../../Assets/images/cultured-img/S3.png'


const CbWhyLabGrown = () => {
  return (
    <>
       <div className='cb_why_lab_grown_section'>
      <Container>
        <Row className='align-items-center'>
            <Col sm={12} md={12} lg={6}>
                <div className='cb_ring_home'>
<Image alt='why lab grown' src={HomeRing} className='img-fluid' />
                </div>
            </Col>
            <Col sm={12} md={12} lg={6}>
            <div className='cb_ring_home_texxt'>
<h2>Why Lab Grown Diamonds? </h2>
<p>Because it's the smart way to buy diamonds. </p>
<p>By definition, diamonds are carbon crystal. Whether grown below the ground or above, the results are the same. Pure Carbon Crystal.</p>
<p>Diamonds grown above are gentler on the environment and don't require harsh working conditions.</p>
<p>Lab grown diamonds are more affordable and enable buyers to have a choice in the way they spend their money.</p>
            </div>
            </Col>
        </Row>
      </Container>
      </div>
    </>
  )
}

export default CbWhyLabGrown
