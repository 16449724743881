import React, { useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Button } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { base_url, postHeader } from '../../Helpers/request';
import { useState } from 'react';
import { toast } from 'react-toastify';
import homebanner from '../../Assets/images/cd-banner.jpg'
import homebanner2 from '../../Assets/images/cb-banner2.png'
import homebannerMobile from '../../Assets/images/cd-banner-mobile.jpg'
import homebannerMobile2 from '../../Assets/images/cd-banner-mobile2.jpg'
import wodstockBanner from '../../Assets/images/woodstock-banner.webp'
import wodstockBannerMobile from '../../Assets/images/woodstock-banner-mobile.webp'
import Slider from 'react-slick';
const BannerSlider = () => {
  const history = useHistory();
  const [banner,setBanner] = useState([]);


  const settingsBannerSlider = {
    dots: false,
    infinite: true,
    arrows:true,
    autoplay:true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  useEffect(()=>{
 //-----------------------------------Banner
 axios.get(base_url + `/home/slider`, {
  headers: postHeader
})
  .then(response => {
    if (response.data.status == 1) {
      setBanner(response.data.data);
      localStorage.setItem("bw-bannerdata", JSON.stringify(response.data.data));
    } else {
      toast.error(response.message, { autoClose: 3000 });
    }
  })
  .catch((error) => {
    console.log(error);
  });
  },[])
  return (
    <>
      {/* {banner?.map((val, index) => */}
        {/* <div className="rcs_hero_slider" style={{ backgroundImage: `url(${isMobileOnly ? homebannerMobile: homebanner})` }}>
          <div className="rcs_hero_img">
            <Container fluid>
              <Row className='m-auto w-100'>
                <Col md={9} className='m-auto'>
                  <div className={isMobile ? 'text-center' : val.text_direction_class}>
                    <h1 data-aos="fade-up-left" data-aos-duration="700">{(val.title)}</h1>
                    <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">{(val.short_text)}</p>
                    <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" onClick={() => { history.push('/' + val.btn_link) }} variant="outline-dark">{val.btn_text}</Button>
                  </div>                  
                </Col>
              </Row>
            </Container>
          </div>
        </div> */}
      

      <div className='cb_banner_slider'>
        <Container fluid>
          <Row>
            <Col sm={12} md={12} lg={12} className='p-0'>
              <Slider {...settingsBannerSlider} className='cb_banner_slider'>
                <div className='outerrr'>
                  <div className="rcs_hero_slider" style={{ backgroundImage: `url(${isMobileOnly ? homebannerMobile: homebanner})` }}>
                  </div>
                </div>
                <div className='outerrr'>
                  <Link to="/jewelry/sale-products"><div className="rcs_hero_slider" style={{ backgroundImage: `url(${isMobileOnly ? homebannerMobile2: homebanner2})` }}><span style={{opacity:'0'}}>Banner</span></div></Link>
                </div>
                <div className='outerrr'>
                  {/* <Link to="/jewelry/sale-products"> */}
                  <div className="rcs_hero_slider" style={{ backgroundImage: `url(${isMobileOnly ? wodstockBannerMobile: wodstockBanner})` }}><span style={{opacity:'0'}}>Banner</span>
                  </div>
                  {/* </Link> */}
                </div>
              </Slider>
            </Col>
          </Row>
        
        </Container>
      
      </div>
    </>
  )
}

export default BannerSlider;