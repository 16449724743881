import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { MdLocalShipping } from 'react-icons/md'
import { TbTruckReturn  } from "react-icons/tb";
import {FaChalkboardUser } from 'react-icons/fa'
import { BsPersonVideo } from "react-icons/bs";
import s1 from '../../Assets/images/cultured-img/s1.png'
import s2 from '../../Assets/images/cultured-img/S2.png'
import s3 from '../../Assets/images/cultured-img/S3.png'

const CbShipping = () => {
  return (
<>

<div className='cb_shipping__home_Section'>
     <Container>
           <Row>
            <Col sm={12} md={4} lg={4}>
                <div className='cb_shipping_text_img first'>
                  <Image src={s1} alt="Shipping icon"/>
                    <h6>Free 2-days shipping</h6>
                </div>
            </Col>
            <Col sm={12} md={4} lg={4}>
                <div className='cb_shipping_text_img second'>
                <Image src={s2} alt="Shipping icon"/>
                    <h6>30-day no Hassle returns</h6>
                    <p>(Excludes Custom Work)</p>
                </div>
            </Col>
            <Col sm={12} md={4} lg={4}>
                <div className='cb_shipping_text_img third'>
                <Image src={s3} alt="Shipping icon"/>
                    <h6>CB concierge</h6>
                    <p>Complimentary White Glove Services</p>
                </div>
            </Col>
           </Row>
        </Container> 
      </div>
</>
  )
}

export default CbShipping
