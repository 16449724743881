import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Banner = ({bannerImg, title, nestedBreadCrumb}) => {
  return (
    <>
      <div className='banner' style={{background: `url(${bannerImg}) no-repeat center/cover`}}>
          <h1 className='text-center m-0'>{title}</h1>
          <Breadcrumb className='edu-breadcrumb'>
              <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
              {nestedBreadCrumb && <BreadcrumbItem><Link to="/education">Education</Link></BreadcrumbItem>}
              <BreadcrumbItem active>{title}</BreadcrumbItem>
          </Breadcrumb>
      </div>
    </>
  )
}

export default Banner;