import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import ReferImg from "../../Assets/images/cultured-img/refer-img.jpg";
import ThankuImg from "../../Assets/images/cultured-img/thank-you.png";
import { NavLink } from "react-router-dom";


const CdOurRewardsPrograms = () => {
  return (
    <>
      <div className="cd_OurRewardsPrograms_section">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="cb_ring_home_texxt text-center mb-5">
                <h2>Our Rewards Programs </h2>
                <p>
                  Cultured Brilliance cares about our customers and those that
                  care for all of us – whenever and wherever it counts.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} >
              <div className="cb_refer_img_box">
                <Image src={ReferImg} alt="refer Image" className="img-fluid" />
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="cb_ring_home_texxt text-center">
                <h2 className="mb-4">Refer A Friend! </h2>
                <NavLink to="/refer-a-friend" className="cd_btn_rama_color" >
                Program Details
                </NavLink>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              md={6}
              lg={6}
              className="d-flex align-items-center justify-content-center order_1"
            >
              <div className="cb_ring_home_texxt text-center">
                <h2 className="mb-3">Thank You For <br></br>
Your Service! </h2>
                <p className="w-100 mb-4"><strong>How we show appreciation!</strong></p>
                <NavLink  to="/diamond-elite" className="cd_btn_rama_color">
                  Program Details
                </NavLink>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} className="order_2">
              <div className="cb_refer_img_box">
                <Image src={ThankuImg} alt="refer Image" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CdOurRewardsPrograms;
